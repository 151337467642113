import { Link } from "@remix-run/react";
import { BsChevronCompactDown } from "react-icons/bs";
import { LuArrowBigRight } from "react-icons/lu";
import type { PartialWeeklyMenu, WeeklyConfig } from "~/utils/constants";
import { dateToString, getMondayOfDate, getNextMonday } from "~/utils/dates";
import { WeeklyMenuRender } from "./Menu";
import { backgroundImage } from "./backgroundLanding";
import "./landing.css";

const configExample: WeeklyConfig = {
  daily: {
    Monday: {
      lunch: "Cook",
      dinner: "Cook",
    },
    Tuesday: {
      lunch: "Skip",
      dinner: "Skip",
    },
    Wednesday: {
      lunch: "Cook",
      dinner: "Cook",
    },
    Thursday: {
      lunch: "Skip",
      dinner: "Cook",
    },
    Friday: {
      lunch: "Cook",
      dinner: "Cook",
    },
    Saturday: {
      lunch: "Skip",
      dinner: "Skip",
    },
    Sunday: {
      lunch: "Skip",
      dinner: "Skip",
    },
  },
  lunchbox: true,
  additionalRequests: "",
  ingredientsToAvoid: "",
  lunchboxInstructions: "",
};

const menuExample: PartialWeeklyMenu = {
  Monday: {
    lunch: {
      title: "Veggie-packed Quinoa Salad",
      description:
        "A colorful salad with quinoa, cherry tomatoes, cucumbers, and olives, dressed in a zesty lemon vinaigrette.",
      isRefrigeratable: true,
      recipeId: "690",
    },
    dinner: {
      title: "Grilled Lemon Herb Chicken",
      description:
        "Tender chicken marinated in a flavorful mix of lemon, garlic, and fresh herbs, served with roasted sweet potatoes and steamed broccoli.",
      isRefrigeratable: false,
      recipeId: "691",
    },
    lunchbox: {
      title: "Mediterranean Pita Pockets",
      description:
        "Whole-wheat pita pockets stuffed with hummus, falafel balls, cherry tomatoes, and cucumber slices, served with a side of carrot and celery sticks.",
      isRefrigeratable: true,
      recipeId: "692",
    },
  },
  Tuesday: {
    lunchbox: {
      title: "DIY Pizza Lunchables",
      description:
        "Whole-grain crackers, pepperoni slices, mozzarella cheese, and marinara sauce for building mini pizzas, served with snap peas and grape tomatoes.",
      isRefrigeratable: true,
      recipeId: "693",
    },
  },
  Wednesday: {
    lunch: {
      title: "Greek Chicken Salad",
      description:
        "Chopped romaine lettuce topped with grilled chicken, feta cheese, Kalamata olives, cucumber, and a tangy Greek dressing.",
      isRefrigeratable: true,
      recipeId: "694",
    },
    dinner: {
      title: "Beef and Veggie Stir-Fry",
      description:
        "Strips of beef sautéed with colorful bell peppers, broccoli, and snap peas in a flavorful ginger soy sauce, served over fluffy jasmine rice.",
      isRefrigeratable: false,
      recipeId: "695",
    },
    lunchbox: {
      title: "Protein-Packed Pasta Salad",
      description:
        "Whole-grain pasta with cherry tomatoes, black olives, mozzarella balls, and grilled chicken, dressed in a light Italian vinaigrette, served with a side of sugar snap peas.",
      isRefrigeratable: true,
      recipeId: "696",
    },
  },
  Thursday: {
    dinner: {
      title: "Vegetarian Quinoa Stuffed Peppers",
      description:
        "Colorful bell peppers filled with a flavorful mix of quinoa, black beans, corn, and Mexican spices, topped with melted cheese.",
      isRefrigeratable: false,
      recipeId: "697",
    },
    lunchbox: {
      title: "DIY Sushi Rolls",
      description:
        "Homemade sushi rolls filled with cucumber, avocado, and imitation crab, served with soy sauce for dipping and edamame on the side.",
      isRefrigeratable: true,
      recipeId: "698",
    },
  },
  Friday: {
    lunch: {
      title: "Caprese Panini",
      description:
        "Pressed panini sandwiches with ripe tomatoes, fresh mozzarella, basil pesto, and a balsamic glaze, served with a side of balsamic-marinated strawberries.",
      isRefrigeratable: true,
      recipeId: "699",
    },
    dinner: {
      title: "Teriyaki Glazed Tofu",
      description:
        "Crispy tofu cubes coated in a sticky teriyaki glaze, served with sautéed sugar snap peas and brown rice.",
      isRefrigeratable: false,
      recipeId: "700",
    },
    lunchbox: {
      title: "Protein-Packed Bento Box",
      description:
        "Hard-boiled eggs, whole-grain crackers, turkey slices, cheddar cheese cubes, cucumber slices, and a side of mixed berries.",
      isRefrigeratable: true,
      recipeId: "701",
    },
  },
};

export const Landing = ({
  hasUser,
  hasMenuNextWeek,
  hasMenuThisWeek,
}: {
  hasUser: boolean;
  hasMenuThisWeek?: boolean;
  hasMenuNextWeek?: boolean;
}) => {
  const thisMonday = dateToString(getMondayOfDate());
  const nextMonday = dateToString(getNextMonday());
  return (
    <>
      <section
        className="bg-right bg-cover relative"
        style={{
          height: "calc(100vh - 64px)",
          backgroundImage: backgroundImage,
        }}
      >
        <div className="pt-[12vh] lg:pt-[20vh] px-6 mx-auto flex flex-wrap flex-col items-center">
          <div className="flex flex-col w-full xl:w-3/5 justify-center lg:items-start overflow-y-hidden">
            <h1 className="my-4 text-3xl md:text-5xl text-primary font-bold leading-tight text-center md:text-left slide-in-bottom-h1">
              The Meal Planner for Families
            </h1>
            <p className="leading-normal text-base md:text-2xl mb-8 text-center md:text-left slide-in-bottom-subtitle">
              Effortlessly craft meal plans, grocery list and recipes in minutes, tailored for busy parents
            </p>

            <div className="pb-8 lg:pb-6 text-center md:text-left fade-in mt-4 ">
              {hasMenuThisWeek && (
                <Link role="button" className="btn btn-primary btn-lg" to={`/plan/${thisMonday}`}>
                  This week's menu <LuArrowBigRight size={20} />
                </Link>
              )}
              {hasMenuThisWeek && <div className="italic text-base-content/60 my-4">or</div>}
              <Link
                role="button"
                className="btn btn-primary btn-lg"
                to={(hasMenuNextWeek ? "/plan" : "/plan/explore") + `/${nextMonday}`}
              >
                {hasMenuNextWeek ? "Next week's menu" : "Plan next week"} <LuArrowBigRight size={20} />
              </Link>
            </div>
          </div>
          <div className="absolute flex items-center flex-col pb-1 fade-in bottom-4 md:bottom-0">
            <div
              className="pb-1"
              onClick={(e) => {
                e.currentTarget.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Example Menu
            </div>
            <BsChevronCompactDown />
          </div>
        </div>
      </section>
      <section className="lg:w-2/3 lg:mx-auto">
        <WeeklyMenuRender config={configExample} menu={menuExample} inProgress={false} hideActions />
        <div className="flex justify-center mt-10">
          <Link role="button" className="btn btn-primary" to="/config">
            Get started for free
            <LuArrowBigRight size={18} />
          </Link>
        </div>
      </section>
    </>
  );
};
