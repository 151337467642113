import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Footer";
import { Landing } from "~/components/Landing";
import { getMondayOfDate, getNextMonday } from "~/utils/dates";
import { hasMeals } from "~/utils/queries.server";
import { getUserId } from "~/utils/sessions.server";

export const meta: MetaFunction = () => {
  return [
    { title: "Feed Us" },
    {
      name: "description",
      content: "You personal family meal planner. Hassle free planning every week.",
    },
    {
      name: "apple-mobile-web-app-title",
      content: "Feed Us",
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const { userId, headers } = await getUserId({ request });
  if (!userId) return json({ hasUser: false, hasMenu: { thisWeek: false, nextWeek: false } }, { headers });

  const thisWeek = getMondayOfDate();
  const nextWeek = getNextMonday();
  const [hasMenuThisWeek, hasMenuNextWeek] = await Promise.all([
    hasMeals(userId, thisWeek),
    hasMeals(userId, nextWeek),
  ]);

  return json({ hasUser: true, hasMenu: { thisWeek: hasMenuThisWeek, nextWeek: hasMenuNextWeek } }, { headers });
}

export default function Index() {
  const data = useLoaderData<typeof loader>();
  return (
    <>
      <Container>
        <Landing
          hasUser={data.hasUser}
          hasMenuNextWeek={data.hasMenu.nextWeek}
          hasMenuThisWeek={data.hasMenu.thisWeek}
        />
      </Container>
      <Footer />
    </>
  );
}
